<template>
    <section>
        <modal-header :items="firstRowHeader"/>
        <modal-header :items="secondRowHeader"/>
    </section>
</template>

<script>
import ModalHeader from "../../../../../ModalHeader.vue";
import {fromCents} from "../../../../../../helpers/balanceConverter";

export default {
    props: {
        totalWin: {
            type: Number
        },
        totalFreeSpins: {
            type: Number
        },
        currentFreeSpin: {
            type: Number
        },
        freeSpinsAwardedCount: {
            type: Number
        }
    },
    computed: {
        firstRowHeader() {
            return {
                'Current free spin': this.currentFreeSpin,
                'Total free spins': this.totalFreeSpins,
            }
        },
        secondRowHeader() {
            return {
                'Free spins awarded': this.freeSpinsAwardedCount,
                'Total free spins win': this.fromCents(this.totalWin),
            }
        }
    },
    methods: {
        fromCents
    },
    components: {
        ModalHeader
    }
}
</script>
