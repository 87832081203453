<template>
    <div>
        <slot name="header"></slot>

        <hr/>

        <div ref="workspace"
             v-resize.initial="handleResize"

             :style="[getScaleStyle, getWorkspaceStyle]"

             class="workspace"
        >
            <slot name="workspace"></slot>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    props: {
        state_data: {
            type: String,
            default() {
                return '{}';
            }
        }
    },
    data() {
        return {
            viewport: {
                width: null,
                height: null,
            },
            scale: null,
            interval: null,
        }
    },
    computed: {
        ...mapGetters('workspace', {
            getWorkspaceWidth: 'getWidth',
            getWorkspaceHeight: 'getHeight'
        }),
        getScaleStyle() {
            let y = 1;

            if (this.getWorkspaceWidth > this.viewport.width && this.getWorkspaceWidth !== null) {
                y = this.viewport.width / this.getWorkspaceWidth;
            }

            return {
                transform: `scale(${y})`
            }
        },
        getWorkspaceStyle() {
            let padding = Math.ceil(this.getWorkspaceHeight / Math.max(this.getWorkspaceWidth, this.viewport.width) * 100);

            return {
                'padding-bottom': `${padding}%`
            }
        },
        clientWidth() {
            return this.$refs.workspace.clientWidth;
        },
        clientHeight() {
            return this.$refs.workspace.clientHeight;
        }
    },
    methods: {
        ...mapActions('loading', {
            startLoading: 'startLoading',
            stopLoading: 'stopLoading',
        }),
        handleResize() {
            if (this.viewport.width !== this.$refs.workspace.clientWidth)
                this.viewport.width = this.$refs.workspace.clientWidth;

            if (this.viewport.height !== this.$refs.workspace.clientHeight)
                this.viewport.height = this.$refs.workspace.clientHeight;
        },
    }
}
</script>

<style scoped>
.workspace {
    height: 0;
    transform-origin: 0 0;
}

hr {
    margin: 5px 0;
    height: 1px;
    background-color: #dee2e6;
}
</style>
